<template>
  <v-dialog
    v-model="show"
    width="50vw"
  >
    <v-card>
      <v-card-title class="headline primary white--text">
        {{ labels.confirmation }}
      </v-card-title>

      <v-card-text class="py-6">
        <v-alert text type="warning">{{ confirmationMsg }}</v-alert>
        <p>{{ labels.proceedConfirmation }}</p>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="$emit('close')"
        >
          {{ labels.dismiss }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click.prevent="callbackMethod"
        >
          {{ labels.confirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { labels } from '@/assets/texts.json';

export default {
  name: 'confirmationDialog',
  props: {
    callbackMethod: {
      type: Function,
      required: true,
      default: () => {},
    },
    confirmationMsg: {
      type: String,
      required: false,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    labels,
  }),
};
</script>

<style scoped>
</style>
